import { MultipleExportButtonInfo, MultipleImportButtonInfo } from './types';

export const SUPPORT_FORM_FILE_SIZE_LIMIT = 3 * 1024 * 1024;
export const SUPPORT_FORM_FILES_LIMIT = 3;
export const FILE_SIZE_LIMIT = 9.9 * 1024 * 1024;
export const FILE_CHUNK_LIMIT = 5 * 1024 * 1024;
export const QR_FILE_SIZE_LIMIT = 100 * 1024 * 1024;
export const FILE_PART_SIZE = 1024 * 1024;
export const LOOKUP_PAGE_SIZE = 100;

export enum activityCategory {
  system = 'SYSTEM',
  subSystem = 'SUBSYSTEM',
  component = 'COMPONENT',
}

export const ACCEPTED_FORMATS_PDF_ALL = { 'application/pdf': ['.pdf'] };
export const ACCEPTED_FORMATS_IMAGE_ALL = {
  'image/jpg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/webp': ['.webp'],
  'image/gif': ['.gif'],
  'image/bmp': ['.bmp'],
  'image/avif': ['.avif'],
  'image/ico': ['.ico'],
};
export const ACCEPTED_FORMATS_JSON_ALL = { 'text/json': ['.json'] };

export const actionPermissions = {
  list: 'list',
  create: 'create',
  edit: 'edit',
  show: 'show',
  delete: 'delete',
  forceDelete: 'forceDelete',
  export: 'export',
  import: 'import',
  certGen: 'certGen',
  certOverride: 'certOverride',
  closeout: 'closeout',
  itrAttachment: 'itrAttachment',
  templateUpdate: 'templateUpdate',
  pdfGen: 'pdfGen',
  sendMobile: 'sendMobile',
};

export enum commentType {
  component = 'COMPONENT',
  subsystem = 'SUBSYSTEM',
  drawing = 'DRAWING',
  system = 'SYSTEM',
  punch = 'PUNCH',
  preservation_item = 'PRESERVATION_ITEM',
  preservation_item_activity = 'PRESERVATION_ITEM_ACTIVITY',
}

export enum yesNoOptions {
  yes = 'YES',
  no = 'NO',
}

export const YES_NO_CHOICES = [
  { id: yesNoOptions.yes, name: 'Yes' },
  { id: yesNoOptions.no, name: 'No' },
];

export enum activityConstraint {
  calculated = 'CALCULATED',
  required = 'REQUIRED',
  optional = 'OPTIONAL',
}

export const ACTIVITY_CONSTRAINT_OPTIONS = [
  { id: activityConstraint.calculated, name: 'Calculated' },
  { id: activityConstraint.required, name: 'Required' },
  { id: activityConstraint.optional, name: 'Optional' },
];

export const ACTIVITY_CATEGORY_OPTIONS = [
  { id: activityCategory.system, name: 'System' },
  { id: activityCategory.subSystem, name: 'Subsystem' },
  { id: activityCategory.component, name: 'Tag' },
];

export enum activityDurationUnit {
  minute = 'MINUTE',
  hour = 'HOUR',
  day = 'DAY',
}

export const ACTIVITY_DURATION_UNIT_CHOICES = [
  { id: activityDurationUnit.minute, name: 'Minute' },
  { id: activityDurationUnit.hour, name: 'Hour' },
  { id: activityDurationUnit.day, name: 'Day' },
];

export enum activityStatusOverall {
  ready = 'Ready',
  notReady = 'Not Ready',
  noCert = 'No Cert',
}

export const ACTIVITY_STATUS_OVERALL_CHOICES = [
  { id: activityStatusOverall.ready, name: activityStatusOverall.ready },
  { id: activityStatusOverall.notReady, name: activityStatusOverall.notReady },
  { id: activityStatusOverall.noCert, name: activityStatusOverall.noCert },
];

export enum activityPredCertStatus {
  ready = 'Ready',
  notReady = 'Not Ready',
  noChildCerts = 'No Child Certs',
}

export const ACTIVITY_PRED_CERT_STATUS_CHOICES = [
  { id: activityPredCertStatus.ready, name: activityPredCertStatus.ready },
  {
    id: activityPredCertStatus.notReady,
    name: activityPredCertStatus.notReady,
  },
  {
    id: activityPredCertStatus.noChildCerts,
    name: activityPredCertStatus.noChildCerts,
  },
];

export enum activityPunchStatus {
  ready = 'Ready',
  notReady = 'Not Ready',
  noPunch = 'No Punch',
}

export const ACTIVITY_PUNCH_STATUS_CHOICES = [
  { id: activityPunchStatus.ready, name: activityPunchStatus.ready },
  {
    id: activityPunchStatus.notReady,
    name: activityPunchStatus.notReady,
  },
  {
    id: activityPunchStatus.noPunch,
    name: activityPunchStatus.noPunch,
  },
];

export enum activityITRStatus {
  ready = 'Ready',
  notReady = 'Not Ready',
  noITRs = 'No ITRs',
}

export const ACTIVITY_ITR_STATUS_CHOICES = [
  { id: activityITRStatus.ready, name: activityITRStatus.ready },
  {
    id: activityITRStatus.notReady,
    name: activityITRStatus.notReady,
  },
  {
    id: activityITRStatus.noITRs,
    name: activityITRStatus.noITRs,
  },
];

export enum strictPrompt {
  strict = 'STRICT',
  prompt = 'PROMPT',
  strictWithPrompt = 'STRICT_WITH_PROMPT',
}

export const STRICT_CHOICES = [
  { id: strictPrompt.strict, name: 'Strict' },
  { id: strictPrompt.prompt, name: 'Prompt' },
  { id: strictPrompt.strictWithPrompt, name: 'Strict with prompt' },
];

export enum templateType {
  certificate = 'CERTIFICATE',
  punch = 'PUNCH',
  preservation = 'PRESERVATION',
  itr = 'ITR',
}

export const DEFAULT_MULTIPLE_IMPORT_BUTTONS: MultipleImportButtonInfo[] = [
  {
    label: 'Import',
  },
];

export const DEFAULT_MULTIPLE_EXPORT_BUTTONS: MultipleExportButtonInfo[] = [
  {
    label: 'Export',
  },
  {
    label: 'Export All',
    includeAllColumns: true,
  },
];

export const PUNCH_CUSTOM_FIELDS = [
  {
    id: 'cfText01',
    name: 'Custom Field Text 1',
  },
  {
    id: 'cfText02',
    name: 'Custom Field Text 2',
  },
  {
    id: 'cfText03',
    name: 'Custom Field Text 3',
  },
  {
    id: 'cfText04',
    name: 'Custom Field Text 4',
  },
  {
    id: 'cfText05',
    name: 'Custom Field Text 5',
  },
];

export const PRICE_FIELDS = ['price', 'totalPrice'];

export enum securityLevel {
  admin = 0,
  fieldAdmin = 1,
  clientAdmin = 10,
  userStandard = 100,
  other = 80,
}

export enum FieldPermissionActions {
  edit = 'edit',
  show = 'show',
}
