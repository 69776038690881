import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { clearLocalStorageListParamsFilter } from '../../../utils/UtilityFunctions';
import useRefreshCompanyContext from '../../common/hooks/useRefreshCompanyContext';
import { updateContext } from '../../../provider/userContext';

const useChangeProject = (options: Options = {}) => {
  const { skipRefreshCompanyContext = false } = options;
  const queryClient = useQueryClient();
  const refreshCompanyContext = useRefreshCompanyContext();

  return useCallback(
    async (projectId: number) => {
      updateContext({ projectId });
      queryClient.getQueryCache().clear();
      clearLocalStorageListParamsFilter();
      if (!skipRefreshCompanyContext) {
        await refreshCompanyContext();
      }
    },
    [queryClient, refreshCompanyContext, skipRefreshCompanyContext]
  );
};

interface Options {
  skipRefreshCompanyContext?: boolean;
}

export default useChangeProject;
