import { Box } from '@mui/material';
import { registerLicense } from '@syncfusion/ej2-base';
import { GanttModel } from '@syncfusion/ej2-gantt/src/gantt/base/gantt-model';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Filter,
  GanttComponent,
  Inject,
  PdfExport,
  Selection,
  UndoRedo,
} from '@syncfusion/ej2-react-gantt';
import React, { ReactElement, useEffect, useMemo } from 'react';
import commonStyles from '../../../common/commonStyles';
import { ganttChartProps, ganttChartEditProps, LICENSE_KEY } from './constants';
import { GanttApi } from './types';
import useGantt from './useGantt';
import './styles.css';

// https://help.syncfusion.com/common/essential-studio/licensing/how-to-register-in-an-application
registerLicense(LICENSE_KEY);

export interface Props
  extends Omit<GanttModel, 'dataSource' | 'actionComplete' | 'rowSelected'> {
  tasks: object[];
  onTaskChange: (args: object) => void;
  onTaskSelect: (args: object) => void;
  onInit: (gantt: GanttApi) => void;
  allowEdit: boolean;
}

export const Gantt: React.FC<Props> = ({
  tasks,
  onTaskChange,
  onTaskSelect,
  onInit,
  allowEdit = false,
  ...rest
}): ReactElement => {
  const { ganttApi, ganttInstanceRef } = useGantt();
  useEffect(() => {
    onInit(ganttApi);
  }, [ganttApi, onInit]);

  const props = useMemo(
    () => ({
      ...ganttChartProps,
      ...(allowEdit
        ? {
            ...ganttChartEditProps,
            actionComplete: onTaskChange,
            rowSelected: onTaskSelect,
          }
        : {}),
    }),
    [allowEdit, onTaskSelect, onTaskChange]
  );

  return (
    <Box sx={commonStyles.flexBox}>
      <GanttComponent
        ref={ganttInstanceRef}
        className="gantt-component"
        dataSource={tasks}
        {...props}
        {...rest}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" allowFiltering={false} />
          <ColumnDirective field="name" width="250" />
          <ColumnDirective field="startDate" />
          <ColumnDirective field="endDate" />
          <ColumnDirective field="duration" width="100" />
          <ColumnDirective field="progress" width="120" />
          <ColumnDirective
            headerText="Predecessor"
            field="dependency"
            allowFiltering={false}
          />
        </ColumnsDirective>
        <Inject services={[Selection, Edit, PdfExport, Filter, UndoRedo]} />
      </GanttComponent>
    </Box>
  );
};
