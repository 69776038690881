import React, { ReactElement, Fragment } from 'react';
import { TextField, useRecordContext } from 'react-admin';
import {
  RESOURCE_COMPONENT,
  RESOURCE_SUBSYSTEM,
  RESOURCE_SYSTEM,
} from '../../constants';
import SmartLinkField from '../../common/SmartLinkField';

interface EntityFieldProps {
  label?: string;
  source?: string;
  textSource?: string;
  sortBy?: string;
  sx?: object;
}

const getEntityType = (record) => {
  if (record.systemId) {
    return 'SYSTEM';
  }
  if (record.subsystemId) {
    return 'SUBSYSTEM';
  }
  if (record.componentId) {
    return 'COMPONENT';
  }
};

const resources = {
  SYSTEM: RESOURCE_SYSTEM,
  SUBSYSTEM: RESOURCE_SUBSYSTEM,
  COMPONENT: RESOURCE_COMPONENT,
};

const entitySource = {
  SYSTEM: 'systemId',
  SUBSYSTEM: 'subsystemId',
  COMPONENT: 'componentId',
};

const entityTextSource = {
  SYSTEM: 'system.systemName',
  SUBSYSTEM: 'subsystem.subsystem',
  COMPONENT: 'component.tag',
};

const makeLabel = (label: string, category: string) => {
  const entityNames = {
    SYSTEM: 'System',
    SUBSYSTEM: 'Subsystem',
    COMPONENT: 'Component',
  };

  return `${entityNames[category]}: ${label}`;
};

const EntityField: React.FC<EntityFieldProps> = ({
  label,
  source,
  sx,
  textSource,
}): ReactElement => {
  const record = useRecordContext();
  const entityType = getEntityType(record);

  return (
    <SmartLinkField
      label={makeLabel(label, entityType)}
      source={entitySource[entityType] || source}
      resource={resources[entityType]}
    >
      <Fragment>
        <div>{makeLabel('', entityType)}</div>
        <TextField
          sx={sx}
          {...{ source: entityTextSource[entityType] || textSource }}
        />
      </Fragment>
    </SmartLinkField>
  );
};

EntityField.defaultProps = {
  label: 'Entity',
  source: 'entity',
  textSource: 'system.systemName',
};

export default EntityField;
