import React, { Fragment, ReactElement } from 'react';
import { BooleanInput } from 'react-admin';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import AssignedWithCreateInput from '../../common/Assigned/AssignedWithCreateInput';
import commonStyles from '../../common/commonStyles';
import { ContactCategories } from '../../common/contactInput/constants';
import CustomDateInput from '../../common/CustomDateInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import TestEquipmentTypeInput from '../../common/TestEquipmentTypeInput';
import TextInput from '../../common/TextInput';
import VendorInput from '../../common/VendorInput/VendorInput';

interface Props {
  bulkEditMode?: boolean;
}

const TestEquipmentCheckoutForm: React.FC<Props> = ({
  bulkEditMode,
}): ReactElement => {
  const isRequired = !bulkEditMode;

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          label="Test Equipment name"
          source="testEquipment.testEquipment"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          label="Manufacturer"
          source="testEquipment.manufacturer"
          sx={commonStyles.flexBoxLongItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          label="Model"
          source="testEquipment.model"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TextInput
          label="Serial Number"
          source="testEquipment.serialNumber"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <BooleanInput
          label="Rent"
          source="testEquipment.rent"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomDateInput
          label="Calibration Date"
          source="testEquipment.calibrationDate"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <CustomDateInput
          label="Calibration Expiration Date"
          source="testEquipment.calibrationExpirationDate"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <CustomNumberInput
          label="Cost"
          source="testEquipment.cost"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <VendorInput
          label="Vendor"
          source="testEquipment.vendorId"
          sx={commonStyles.flexBoxItem}
          disabled
        />
        <TestEquipmentTypeInput
          source="testEquipment.testEquipmentTypeId"
          sx={commonStyles.flexBoxItem}
          disabled
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <AssignedWithCreateInput
          label="Responsible"
          category={ContactCategories.itrEmpWorkGrp}
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
        <CustomDateInput
          label="Check Out Date"
          source="checkOutDate"
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
          defaultValue={dayjs()}
          maxDate={dayjs()}
        />
        <CustomDateInput
          label="Check In Date"
          source="checkInDate"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Fragment>
  );
};

export default TestEquipmentCheckoutForm;
