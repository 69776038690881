import React, { Fragment, ReactElement, useEffect } from 'react';
import { maxLength, useGetOne, usePermissions } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import Box from '@mui/material/Box';
import { ContactCategories } from '../common/contactInput/constants';
import ContactInput from '../common/contactInput/ContactInput';
import PunchPriorityInput from '../common/PunchPriorityInput';
import PunchCategoryInput from '../common/PunchCategoryInput';
import EquipmentTypeInput from '../common/EquipmentTypeInput';
import { validateStatusFields } from '../common/validators';
import CustomDateInput from '../common/CustomDateInput';
import DisciplineInput from '../common/DisciplineInput';
import ComponentInput from '../common/ComponentInput';
import SubsystemInput from '../common/SubsystemInput';
import LocationInput from '../common/LocationInput';
import commonStyles from '../common/commonStyles';
import VendorInput from '../common/VendorInput/VendorInput';
import TextInput from '../common/TextInput';
import {
  hasEditOrShowFieldAccess,
  hasFieldEditAccess,
  required,
  useDictionaryLabelsWithResource,
} from '../../utils/UtilityFunctions';
import { RESOURCE_COMPONENT, RESOURCE_PUNCH } from '../constants';
import useAutoSetHighPunchPriorityForRegulatoryItemComponent from './useAutoSetHighPunchPriorityForRegulatoryItemComponent';
import { getPunchNoValidation } from './validators';

interface PunchFormProps {
  editMode?: boolean;
  bulkEditMode?: boolean;
  disableFieldsWithoutPermissionRules?: boolean;
}

const PunchForm: React.FC<PunchFormProps> = ({
  editMode,
  bulkEditMode,
  disableFieldsWithoutPermissionRules = false,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const { setValue, getValues } = useFormContext();
  const { permissions } = usePermissions();
  const requiredValidation = bulkEditMode ? [] : [required()];
  const isRequired = !bulkEditMode;
  const componentId = useWatch({ name: 'componentId' });
  const { data: component } = useGetOne(
    RESOURCE_COMPONENT,
    { id: componentId },
    { enabled: !!componentId }
  );
  useAutoSetHighPunchPriorityForRegulatoryItemComponent();

  useEffect(() => {
    const formValue = getValues();

    if (!component) {
      return;
    }

    setValue('subsystemId', component.subsystemId);
    setValue('locationId', component.locationId);
    setValue('disciplineId', component.disciplineId);
    !formValue.vendorId && setValue('vendorId', component.vendorId);
    !formValue.equipTypeId && setValue('equipTypeId', component.equipTypeId);
  }, [component, setValue, getValues]);

  return (
    <Fragment>
      <Box sx={commonStyles.flexBox}>
        {editMode && (
          <TextInput
            source="status"
            label={labels['status'] || 'Status'}
            sx={commonStyles.flexBoxItem}
            disabled
          />
        )}
        <TextInput
          source="punchNo"
          label={labels['punchNo'] || 'Punch No'}
          validate={getPunchNoValidation(editMode, bulkEditMode)}
          sx={commonStyles.flexBoxItem}
          disabled={bulkEditMode}
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="description"
          label={labels['description'] || 'Description'}
          validate={[...requiredValidation, maxLength(200)]}
          sx={commonStyles.flexBoxDescriptionItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ComponentInput
          sx={commonStyles.flexBoxItem}
          label={labels['componentId']}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <PunchPriorityInput
          isRequired={isRequired}
          sx={commonStyles.flexBoxItem}
          label={labels['priorityId']}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <EquipmentTypeInput
          source={editMode ? 'component.equipTypeId' : 'equipTypeId'}
          sx={commonStyles.flexBoxItem}
          disabled
        />
        {!editMode && (
          <SubsystemInput
            isRequired={isRequired}
            sx={commonStyles.flexBoxItem}
            label={labels['subsystemId']}
            readOnly={disableFieldsWithoutPermissionRules}
          />
        )}
      </Box>
      {editMode && (
        <Fragment>
          <Box sx={commonStyles.flexBox}>
            <SubsystemInput
              isRequired={isRequired}
              sx={commonStyles.flexBoxItem}
              label={labels['subsystemId']}
              readOnly={disableFieldsWithoutPermissionRules}
            />
            <TextInput
              source="subsystem.phase"
              label="SubSystem Phase"
              sx={commonStyles.flexBoxItem}
              disabled
            />
            <TextInput
              source="subsystem.description"
              label="SubSystem Description"
              sx={commonStyles.flexBoxLongItem}
              disabled
            />
          </Box>
          <Box sx={commonStyles.flexBox}>
            <TextInput
              source="subsystem.system.systemName"
              label="System Name"
              sx={commonStyles.flexBoxItem}
              disabled
            />
            <TextInput
              source="subsystem.system.description"
              label="System Description"
              sx={commonStyles.flexBoxLongItem}
              disabled
            />
          </Box>
        </Fragment>
      )}
      <Box sx={commonStyles.flexBox}>
        <ContactInput
          category={ContactCategories.punchSignOff}
          source="raisedBy"
          label={labels['raisedBy'] || 'Raised By'}
          validate={[...requiredValidation, maxLength(75)]}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <CustomDateInput
          source="raisedDate"
          label={labels['raisedDate'] || 'Raised Date *'}
          validate={requiredValidation}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        {hasEditOrShowFieldAccess(
          permissions,
          `${RESOURCE_PUNCH}.clearedBy`
        ) && (
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="clearedBy"
            label={labels['clearedBy'] || 'Cleared By'}
            validate={[validateStatusFields('cleared'), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
            readOnly={
              !hasFieldEditAccess(permissions, `${RESOURCE_PUNCH}.clearedBy`)
            }
          />
        )}
        {hasEditOrShowFieldAccess(
          permissions,
          `${RESOURCE_PUNCH}.clearedDate`
        ) && (
          <CustomDateInput
            source="clearedDate"
            label={labels['clearedDate'] || 'Cleared Date'}
            validate={validateStatusFields('cleared')}
            sx={commonStyles.flexBoxItem}
            readOnly={
              !hasFieldEditAccess(permissions, `${RESOURCE_PUNCH}.clearedDate`)
            }
          />
        )}
      </Box>
      <Box sx={commonStyles.flexBox}>
        {hasEditOrShowFieldAccess(
          permissions,
          `${RESOURCE_PUNCH}.acceptedBy`
        ) && (
          <ContactInput
            category={ContactCategories.punchSignOff}
            source="acceptedBy"
            label={labels['acceptedBy'] || 'Accepted By'}
            validate={[validateStatusFields('accepted'), maxLength(75)]}
            sx={commonStyles.flexBoxItem}
            readOnly={
              !hasFieldEditAccess(permissions, `${RESOURCE_PUNCH}.acceptedBy`)
            }
          />
        )}
        {hasEditOrShowFieldAccess(
          permissions,
          `${RESOURCE_PUNCH}.acceptedDate`
        ) && (
          <CustomDateInput
            source="acceptedDate"
            label={labels['acceptedDate'] || 'Accepted Date'}
            validate={validateStatusFields('accepted')}
            sx={commonStyles.flexBoxItem}
            readOnly={
              !hasFieldEditAccess(permissions, `${RESOURCE_PUNCH}.acceptedDate`)
            }
          />
        )}
        <CustomDateInput
          source="requiredDate"
          label={labels['requiredDate'] || 'Required Date'}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <CustomDateInput
          source="estimatedClearDate"
          label={labels['estimatedClearDate'] || 'Estimated Clear Date'}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ContactInput
          category={ContactCategories.punchSignOff}
          source="responsible"
          label={labels['responsible'] || 'Responsible'}
          validate={maxLength(75)}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <PunchCategoryInput
          sx={commonStyles.flexBoxItem}
          label={labels['punchCatId']}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <LocationInput
          sx={commonStyles.flexBoxItem}
          label={labels['locationId']}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <DisciplineInput
          label={labels['disciplineId']}
          isRequired={isRequired}
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <ContactInput
          category={ContactCategories.punchSignOff}
          source="actionee"
          label={labels['actionee'] || 'Actionee'}
          validate={maxLength(75)}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <TextInput
          source="poRmaNo"
          label={labels['poRmaNo'] || 'Po Rma No'}
          validate={maxLength(75)}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        <VendorInput disabled={disableFieldsWithoutPermissionRules} />
        <TextInput
          source="workpackOverride"
          label={labels['workpackOverride'] || 'Workpack Override'}
          validate={maxLength(75)}
          sx={commonStyles.flexBoxItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
      </Box>
      <Box sx={commonStyles.flexBox}>
        <TextInput
          source="correctiveAction"
          label={labels['correctiveAction'] || 'Corrective Action'}
          validate={maxLength(512)}
          sx={commonStyles.flexBoxLongItem}
          readOnly={disableFieldsWithoutPermissionRules}
        />
        {labels['cfText01'] ? (
          <TextInput
            source="cfText01"
            label={labels['cfText01']}
            validate={maxLength(512)}
            sx={commonStyles.flexBoxLongItem}
            readOnly={disableFieldsWithoutPermissionRules}
          />
        ) : null}
      </Box>
      <Box sx={commonStyles.flexBox}>
        {labels['cfText02'] ? (
          <TextInput
            source="cfText02"
            label={labels['cfText02']}
            validate={maxLength(512)}
            sx={commonStyles.flexBoxLongItem}
            readOnly={disableFieldsWithoutPermissionRules}
          />
        ) : null}
        {labels['cfText03'] ? (
          <TextInput
            source="cfText03"
            label={labels['cfText03']}
            validate={maxLength(512)}
            sx={commonStyles.flexBoxLongItem}
            readOnly={disableFieldsWithoutPermissionRules}
          />
        ) : null}
      </Box>
      <Box sx={commonStyles.flexBox}>
        {labels['cfText04'] ? (
          <TextInput
            source="cfText04"
            label={labels['cfText04']}
            validate={maxLength(512)}
            sx={commonStyles.flexBoxLongItem}
            readOnly={disableFieldsWithoutPermissionRules}
          />
        ) : null}
        {labels['cfText05'] ? (
          <TextInput
            source="cfText05"
            label={labels['cfText05']}
            validate={maxLength(512)}
            sx={commonStyles.flexBoxLongItem}
            readOnly={disableFieldsWithoutPermissionRules}
          />
        ) : null}
      </Box>
    </Fragment>
  );
};

export default PunchForm;
