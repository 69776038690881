import { useMutation, useQueryClient } from '@tanstack/react-query';
import lodash, { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useDataProvider, useNotify, useResourceContext } from 'react-admin';
import { getErrorMessage } from '../../utils/UtilityFunctions';
import { RESOURCE_ACTIVITY_PREDECESSOR } from '../constants';
import { IMPORT_RESULT_STATUS } from '../notification/type';
import { GanttApi, Task } from './components/gantt/types';
import { getModifiedDataToSave } from './helpers';
import { Activity, ModifiedData } from './types';

const useGanttChartView = (ganttApi: GanttApi) => {
  const resource = useResourceContext();
  const [initialData, setInitialData] = useState<Activity[]>();
  const [selectedId, setSelectedId] = useState<number>(null);
  const [changes, setChanges] = useState<{ [id: number]: Task }>(null);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const queryClient = useQueryClient();
  const clearCache = useCallback(() => {
    [resource, RESOURCE_ACTIVITY_PREDECESSOR].forEach((item) => {
      queryClient.removeQueries({ queryKey: [item], exact: false });
    });
  }, [queryClient, resource]);

  const onCleanState = useCallback(() => {
    ganttApi.clear();
    setSelectedId(null);
    setChanges(null);
  }, [setSelectedId, setChanges, ganttApi]);

  const onTaskSelect = useCallback(
    ({ data }) => {
      setSelectedId(data?.id);
    },
    [setSelectedId]
  );

  const onTaskChange = useCallback(
    ({ modifiedTaskData: updates, requestType }) => {
      if (requestType !== 'save') return;

      setChanges((prev) => {
        const updatesById = lodash(updates)
          .keyBy(({ id }) => id)
          .value();
        return { ...(prev || {}), ...updatesById };
      });
    },
    [setChanges]
  );

  const { mutate: saveChanges } = useMutation({
    mutationFn: async (params: ModifiedData) => {
      const { activities, predecessors } = params;

      if (!isEmpty(predecessors.deleted)) {
        await dataProvider.deleteMany(RESOURCE_ACTIVITY_PREDECESSOR, {
          ids: predecessors.deleted,
        });
      }

      if (!isEmpty(predecessors.created)) {
        await dataProvider.createMany(RESOURCE_ACTIVITY_PREDECESSOR, {
          data: predecessors.created,
        });
      }

      if (!isEmpty(predecessors.updated)) {
        await dataProvider.bulkUpdate(
          RESOURCE_ACTIVITY_PREDECESSOR,
          predecessors.updated
        );
      }

      return dataProvider.bulkUpdate(resource, activities);
    },
  });

  const onBulkSave = useCallback(() => {
    const modifiedData = getModifiedDataToSave(
      initialData,
      Object.values(changes)
    );

    saveChanges(modifiedData, {
      onSuccess: ({ data }) => {
        const { status, totalErrorsCount } = data;
        if (status === IMPORT_RESULT_STATUS.SUCCESS) {
          notify('Elements updated');
        } else {
          const msg = `Failed to update ${totalErrorsCount} elements.`;
          notify(msg, { type: 'error' });
        }
      },
      onError: (error) => {
        notify(getErrorMessage(error), { type: 'error' });
      },
      onSettled: async () => {
        clearCache();
        onCleanState();
      },
    });

    onCleanState();
  }, [onCleanState, saveChanges, notify, changes, initialData, clearCache]);

  return {
    changes,
    setInitialData,
    selectedId,
    onCleanState,
    onBulkSave,
    onTaskChange,
    onTaskSelect,
  };
};

export default useGanttChartView;
