import React, { ReactElement } from 'react';
import { TextField, InfiniteList, useStore } from 'react-admin';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import { RESOURCE_AUDIT } from '../constants';
import ListTitle from '../common/ListTitle';
import AuditFiltersSidebar from './components/AuditFiltersSidebar';
import AuditTableNameField from './components/AuditTableNameField';
import BulkActionButtons from './components/BulkActionButtons';
import ColumnActions from './components/ColumnActions';
import DifferenceField from './components/DifferenceField';
import AuditActions from './components/AuditActions';

const AuditList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_AUDIT);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <InfiniteList
      title={<ListTitle />}
      aside={
        showSideFilter ? (
          <AuditFiltersSidebar defaultFilter={defaultFilter} />
        ) : null
      }
      sx={{ width: '100%' }}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      actions={
        <AuditActions
          withExportButton
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
        />
      }
      perPage={50}
      filter={defaultFilter}
      filterDefaultValues={defaultFilter}
    >
      <DatagridConfigurableRbac
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'tableName',
          'recordId',
          'groupAction',
          'action',
          'diff',
          'updatedAt',
          'updatedBy',
          'updatedByName',
        ]}
      >
        {/*For big JSON column, the database query errors (out of sort memory)*/}
        <TextField source="id" label="Id" sortable={false} />
        <AuditTableNameField source="tableName" label="Table" sortable={true} />
        <TextField source="recordId" label="Record Id" sortable={false} />
        <TextField source="groupAction" label="Group Action" sortable={false} />
        <TextField source="action" label="Action" sortable={false} />
        <DifferenceField source="diff" label="Difference" sortable={false} />
        {renderMetaDataFields(true)}
        <ColumnActions />
      </DatagridConfigurableRbac>
    </InfiniteList>
  );
};

export default AuditList;
