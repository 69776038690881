import React, { Fragment, ReactElement } from 'react';
import {
  SimpleForm,
  ListButton,
  TextField,
  DateField,
  Labeled,
  useRecordContext,
  maxLength,
  usePermissions,
} from 'react-admin';
import { Card, Box, Typography, CardContent } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../../assets/icons/functionalIcons/Cancel.svg';
import { RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY } from '../../../components/constants';
import CompletedInput from '../../preservationItemActivity/components/CompletedInput';
import useCurrentProjectSettings from '../../common/hooks/useCurrentProjectSettings';
import { CompletedType } from '../../preservationItemActivity/constants';
import { ContactCategories } from '../../common/contactInput/constants';
import SmartTabWidthButtons from '../../common/SmartTabWidthButtons';
import ContactInput from '../../common/contactInput/ContactInput';
import { SmartPreservationItemActivityType } from '../types';
import useCanAccess from '../../common/hooks/useCanAccess';
import CustomDateInput from '../../common/CustomDateInput';
import SignatureInput from '../../common/SignatureInput';
import FooterToolbar from '../../common/FooterToolbar';
import LocationField from '../../common/LocationField';
import commonStyles from '../../common/commonStyles';
import {
  getValidateRequiredFunc,
  getValidateRequiredFuncByValue,
} from '../../preservationItemActivity/validators';
import TestField from '../../common/TestField';
import TextInput from '../../common/TextInput';
import {
  hasEditOrShowFieldAccess,
  hasFieldEditAccess,
  useDictionaryLabelsWithResource,
} from '../../../utils/UtilityFunctions';
import PrintNameInput from './PrintNameInput';
import JSONFormTab from './JSONFormTab';

interface SmartPreservationItemActivityFormProps {
  onCancel?: () => void;
  setPendingPunchTitle?: (title: string | null) => void;
  setPendingPunchCreateDialogCloseDisabled: (value: boolean) => void;
}

const SmartPreservationItemActivityForm: React.FC<
  SmartPreservationItemActivityFormProps
> = ({
  onCancel,
  setPendingPunchTitle,
  setPendingPunchCreateDialogCloseDisabled,
}): ReactElement => {
  const { labels } = useDictionaryLabelsWithResource();
  const canAccessList = useCanAccess();
  const record = useRecordContext<SmartPreservationItemActivityType>();
  const isSimpleForm = record && !!record.storagePreservation;
  const { permissions } = usePermissions();
  const {
    currentProjectSettings: { presAutomation },
  } = useCurrentProjectSettings();

  return (
    <SimpleForm
      toolbar={
        <FooterToolbar autoSave={true} onCancel={onCancel}>
          <ListButton label="Cancel" icon={<CancelIcon />} onClick={onCancel} />
        </FooterToolbar>
      }
    >
      <Box sx={{ ...commonStyles.columnFlexBox, padding: '0 16px 30px' }}>
        <SmartTabWidthButtons />
        <Labeled label={labels['componentId'] || 'Tag'}>
          <TextField source="component.tag" />
        </Labeled>
        <Labeled label="Tag Description">
          <TextField source="component.tagDescription" />
        </Labeled>
        <Labeled label={labels['testId'] || 'Test'}>
          <TestField source="testId" textSource="test.testFormNo" />
        </Labeled>
        <Labeled label="Test Description">
          <TextField source="test.testName" />
        </Labeled>
        <Labeled label={labels['dueDate'] || 'Due Date'}>
          <DateField source="dueDate" />
        </Labeled>
        <Labeled label={labels['locationId'] || 'Location'}>
          <LocationField />
        </Labeled>
        <Labeled
          label={labels['storagePreservation'] || 'Storage Preservation'}
        >
          <TextField source="storagePreservation" />
        </Labeled>
        <Labeled label="Review Comments">
          <TextInput source="reviewComments" label="" multiline fullWidth />
        </Labeled>
      </Box>

      {!isSimpleForm && (
        <JSONFormTab
          setPendingPunchTitle={setPendingPunchTitle}
          setPendingPunchCreateDialogCloseDisabled={
            setPendingPunchCreateDialogCloseDisabled
          }
        />
      )}

      <Card sx={{ marginBottom: '20px', width: '100%' }}>
        <Typography variant="h5" sx={{ margin: '20px' }}>
          {`Status${isSimpleForm ? ', Comments and Signatures' : ''}`}
        </Typography>
        <CardContent>
          {hasEditOrShowFieldAccess(
            permissions,
            `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.completed`
          ) && (
            <CompletedInput
              source="completed"
              label={labels['completed'] || 'Status'}
              sx={commonStyles.flexBoxFullWidthItem}
              disabled={
                !hasFieldEditAccess(
                  permissions,
                  `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.completed`
                )
              }
            />
          )}
          {isSimpleForm && (
            <Fragment>
              <TextInput
                source="comments"
                label="Comments"
                sx={{ ...commonStyles.flexBoxFullWidthItem, flexGrow: 1 }}
                fullWidth
                multiline
                disabled={!canAccessList.edit}
              />
              {hasEditOrShowFieldAccess(
                permissions,
                `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.inspector`
              ) && (
                <Fragment>
                  <SignatureInput
                    source="inspector"
                    label={labels['inspector'] || 'Inspector'}
                    sx={[
                      commonStyles.flexBoxFullWidthItem,
                      commonStyles.bottomMargin,
                    ]}
                    disabled={
                      !hasFieldEditAccess(
                        permissions,
                        `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.inspector`
                      )
                    }
                  >
                    <ContactInput
                      source="inspector"
                      label={labels['inspector'] || 'Inspector'}
                      sx={commonStyles.flexBoxFullWidthItem}
                      validate={[
                        getValidateRequiredFuncByValue(
                          'completed',
                          CompletedType.YES
                        ),
                      ]}
                      category={ContactCategories.preservationEmpWorkGrp}
                      disabled={
                        !hasFieldEditAccess(
                          permissions,
                          `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.inspector`
                        )
                      }
                    />
                  </SignatureInput>
                  <PrintNameInput
                    source="inspectorPrintName"
                    label={
                      labels['inspectorPrintName'] || 'Inspector Print Name'
                    }
                    sx={commonStyles.flexBoxFullWidthItem}
                    disabled={!hasFieldEditAccess(permissions, 'inspector')}
                    validate={[
                      getValidateRequiredFuncByValue(
                        'completed',
                        CompletedType.YES
                      ),
                      maxLength(100),
                    ]}
                  />
                </Fragment>
              )}
              {hasEditOrShowFieldAccess(
                permissions,
                `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.signOffDate`
              ) && (
                <CustomDateInput
                  source="signOffDate"
                  label={labels['signOffDate'] || 'Sign Off Date'}
                  sx={commonStyles.flexBoxFullWidthItem}
                  validate={[
                    getValidateRequiredFuncByValue(
                      'completed',
                      CompletedType.YES
                    ),
                  ]}
                  disabled={
                    !hasFieldEditAccess(
                      permissions,
                      `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.signOffDate`
                    )
                  }
                />
              )}
              {hasEditOrShowFieldAccess(
                permissions,
                `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`
              ) && (
                <SignatureInput
                  source="verifiedBy"
                  label={labels['verifiedBy'] || 'Verified By'}
                  sx={[
                    commonStyles.flexBoxFullWidthItem,
                    commonStyles.bottomMargin,
                  ]}
                  disabled={
                    !hasFieldEditAccess(
                      permissions,
                      `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`
                    )
                  }
                >
                  <ContactInput
                    source="verifiedBy"
                    label={labels['verifiedBy'] || 'Verified By'}
                    sx={commonStyles.flexBoxFullWidthItem}
                    validate={[
                      getValidateRequiredFunc('verifiedDate', 'Verified Date'),
                      presAutomation === 'YES' &&
                        getValidateRequiredFuncByValue(
                          'completed',
                          CompletedType.YES
                        ),
                    ]}
                    category={ContactCategories.preservationEmpWorkGrp}
                    disabled={
                      !hasFieldEditAccess(
                        permissions,
                        `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedBy`
                      )
                    }
                  />
                </SignatureInput>
              )}
              <PrintNameInput
                source="verifiedPrintName"
                label={labels['verifiedPrintName'] || 'Verified Print Name'}
                sx={commonStyles.flexBoxFullWidthItem}
                disabled={!canAccessList.edit}
                validate={[
                  maxLength(100),
                  presAutomation === 'YES' &&
                    getValidateRequiredFuncByValue(
                      'completed',
                      CompletedType.YES
                    ),
                ]}
              />
              {hasEditOrShowFieldAccess(
                permissions,
                `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedDate`
              ) && (
                <CustomDateInput
                  source="verifiedDate"
                  label={labels['verifiedDate'] || 'Verified Date'}
                  sx={commonStyles.flexBoxFullWidthItem}
                  validate={[
                    getValidateRequiredFunc('verifiedBy', 'Verified By'),
                    presAutomation === 'YES' &&
                      getValidateRequiredFuncByValue(
                        'completed',
                        CompletedType.YES
                      ),
                  ]}
                  disabled={
                    !hasFieldEditAccess(
                      permissions,
                      `${RESOURCE_SMART_PRESERVATION_ITEM_ACTIVITY}.verifiedDate`
                    )
                  }
                />
              )}
            </Fragment>
          )}
        </CardContent>
      </Card>
    </SimpleForm>
  );
};

export default SmartPreservationItemActivityForm;
