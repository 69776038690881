import { AuthProvider, UserIdentity } from 'react-admin';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import axios, { AxiosHeaders } from 'axios';
import { api } from '../../api';
import {
  getContext,
  setContext,
  updateContext,
  UserContext,
} from '../../userContext';
import {
  clearNotificationContext,
  initNotificationContext,
} from '../../notificationContext';
import {
  sleep,
  updateDictionaryContext,
  updateLocalUISettings,
} from '../../../utils/UtilityFunctions';

export type CognitoAuthProviderOptionsPool = CognitoUserPool;

export type CognitoAuthProviderOptionsIds = {
  userPoolId: string;
  clientId: string;
  hostedUIUrl?: string;
  cognitoDomain?: string;
  mode: 'oauth' | 'username';
  redirect_uri: string;
  scope: string[];
};

//https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/

export type CognitoAuthProviderOptions =
  | CognitoAuthProviderOptionsPool
  | CognitoAuthProviderOptionsIds;

export type AuthProviderExt = AuthProvider & {
  getUserPool: () => CognitoUserPool;
  getOptions: () => CognitoAuthProviderOptions;
  refreshUserSession: () => Promise<void>;
};

const checkUserSessionChecker = () => {
  const promiseMap = new Map();
  return (user) => {
    if (!promiseMap.has(user.username)) {
      promiseMap.set(
        user.username,
        new Promise<void>((resolve, reject) => {
          user.getSession((err, session) => {
            if (err || !session.isValid()) {
              if (err) {
                // eslint-disable-next-line no-console
                console.log('User getSession failed', err);
              } else {
                // eslint-disable-next-line no-console
                console.log('User session is not valid!');
              }
              reject();
            } else {
              updateContext({
                token: session.getIdToken().getJwtToken(),
                tokenExp: session.getIdToken().decodePayload().exp,
              });
              user.getUserAttributes((err) => {
                if (err) {
                  // eslint-disable-next-line no-console
                  console.log('User getUserAttributes failed!', err);
                  reject();
                } else {
                  resolve();
                }
              });
            }
          });
        }).finally(() => {
          promiseMap.delete(user.username);
        })
      );
    }

    return promiseMap.get(user.username);
  };
};

export const CognitoAuthProvider = (
  options: CognitoAuthProviderOptions
): AuthProviderExt => {
  const mode = options instanceof CognitoUserPool ? 'username' : options.mode;
  const userSessionCheck = checkUserSessionChecker();

  const redirectToOAuth = async () => {
    setContext(undefined);
    await clearNotificationContext();
    if (mode === 'oauth') {
      const oauthOptions = options as CognitoAuthProviderOptionsIds;
      setTimeout(() => {
        window.location.href = `${oauthOptions.hostedUIUrl}/login?client_id=${
          oauthOptions.clientId
        }&response_type=code&scope=${oauthOptions.scope.join(
          '+'
        )}&redirect_uri=${oauthOptions.redirect_uri}`;
      }, 100);
    } else {
      throw 'Not supported mode:' + mode;
    }
  };

  const userPool =
    options instanceof CognitoUserPool
      ? (options as CognitoUserPool)
      : new CognitoUserPool({
          UserPoolId: options.userPoolId,
          ClientId: options.clientId,
        });

  const refreshUserSession = () => {
    return new Promise<void>((resolve, reject) => {
      const user = userPool.getCurrentUser();
      const tokenExp = getContext().tokenExp;
      if (!tokenExp || tokenExp < Date.now() / 1000) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            const token = session.getIdToken().getJwtToken();
            updateContext({
              token,
              tokenExp: session.getIdToken().decodePayload().exp,
            });
            resolve(token);
          }
        });
      } else {
        resolve();
      }
    });
  };

  return {
    getOptions: () => options,
    getUserPool: () => userPool,
    refreshUserSession,
    async login(): Promise<void> {
      await redirectToOAuth();
      return Promise.resolve();
    },

    // called when the user clicks on the logout button
    async logout() {
      return new Promise<void>(async (resolve) => {
        setContext(undefined);
        await clearNotificationContext();
        const user = userPool.getCurrentUser();
        if (!user) {
          return resolve();
        }
        user.signOut(() => {
          resolve();
        });
      });
    },
    // called when the API returns an error
    async checkError({ status }) {
      if (status === 401 || status === 403) {
        const user = userPool.getCurrentUser();
        return user
          ? refreshUserSession()
          : Promise.reject({ message: 'Unauthorized' });
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth() {
      return new Promise<void>(async (resolve, reject) => {
        const user = userPool.getCurrentUser();
        if (!user) {
          await redirectToOAuth();
          reject();
        } else {
          userSessionCheck(user)
            .then(() => resolve())
            .catch(() => redirectToOAuth());
        }
      });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    async getPermissions() {
      return new Promise((resolve, reject) => {
        try {
          const user = userPool.getCurrentUser();

          if (!user) {
            redirectToOAuth();
            return reject();
          }
          const ctx: UserContext = getContext();
          if (!ctx) {
            return reject({ status: 401, message: 'No Context' });
          }
          const companyName = ctx?.company;
          const projectId = ctx?.projectId;
          const effectivePermissions =
            ctx?.companies?.[companyName]?.projects?.[projectId]
              ?.effectivePermissions || [];

          userSessionCheck(user)
            .then(() => resolve(effectivePermissions))
            .catch(() => reject());
        } catch (e) {
          return reject('Could not get user permissions!');
        }
      });
    },

    async getIdentity() {
      return new Promise<UserIdentity>((resolve, reject) => {
        const ctx: UserContext = getContext();
        const user = userPool.getCurrentUser();

        if (!user) {
          return reject();
        }

        userSessionCheck(user)
          .then(() => {
            if (ctx?.id && ctx.fullName) {
              resolve({
                id: ctx.id,
                fullName: ctx.fullName,
                email: ctx.id,
              });
            } else {
              reject();
            }
          })
          .catch(() => reject());
      });
    },

    async handleCallback() {
      const urlParams = new URLSearchParams(
        window.location.search || window.location.hash.substr(1)
      );
      const error = urlParams.get('error');
      const code = urlParams.get('code');
      const errorDescription = urlParams.get('error_description');
      let idToken = urlParams.get('id_token');
      let refreshToken = urlParams.get('refresh_token');
      let accessToken = urlParams.get('access_token');

      if (code) {
        const oauthOptions = options as CognitoAuthProviderOptionsIds;
        const url = `${oauthOptions.cognitoDomain}/oauth2/token`;
        const requestData = {
          grant_type: 'authorization_code',
          client_id: oauthOptions.clientId,
          redirect_uri: oauthOptions.redirect_uri,
          code,
        };
        const result = await axios.post(url, requestData, {
          headers: new AxiosHeaders({
            'content-type': 'application/x-www-form-urlencoded',
          }),
        });
        idToken = result.data.id_token;
        accessToken = result.data.access_token;
        refreshToken = result.data.refresh_token;
      }

      if (error) {
        return Promise.reject({
          logoutOnFailure: false,
          message: errorDescription,
        });
      }

      if (idToken == null || accessToken == null) {
        return Promise.reject({
          logoutOnFailure: false,
          message: 'Failed to handle login callback.',
        });
      }
      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({ IdToken: idToken }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: refreshToken,
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: accessToken,
        }),
      });
      const user = new CognitoUser({
        Username: session.getIdToken().decodePayload()['cognito:username'],
        Pool: userPool,
        Storage: window.localStorage,
      });
      user.setSignInUserSession(session);
      // ##############################################
      // setToken
      // ##############################################
      updateContext({
        token: session.getIdToken().getJwtToken(),
        tokenExp: session.getIdToken().decodePayload().exp,
      });

      try {
        const response = await api.user.getMyDetails();

        if (response.data.companies) {
          const ctx: UserContext = getContext();
          ctx.companies = response.data.companies;
          ctx.id = response.data.id;
          ctx.fullName = `${response.data.firstName} ${response.data.lastName}`;
          let selectedCompany = Object.keys(ctx.companies).find(
            (company) =>
              ctx.companies[company].isPreferred &&
              !ctx.companies[company].isCompanyAccessDisabled
          );
          if (!selectedCompany) {
            selectedCompany = Object.keys(ctx.companies).find(
              (company) => !ctx.companies[company].isCompanyAccessDisabled
            );
          }
          if (!selectedCompany) {
            return Promise.reject({
              logoutOnFailure: false,
              message: "Couldn't find preferred company!",
            });
          }
          ctx.company = selectedCompany;
          ctx.defaultDivisionId =
            ctx.companies[selectedCompany].defaultDivisionId;
          ctx.ablyToken = response.data.ablyToken;
          ctx.fpmsApiKey = response.data.fpmsApiKey;
          ctx.isRev1Admin = response.data.isRev1Admin;
          ctx.isTermsAndPrivacyPolicyAccepted =
            response.data.isTermsAndPrivacyPolicyAccepted;
          updateContext(ctx);
          initNotificationContext(ctx.ablyToken, ctx.company, ctx.id);

          const uiSettings =
            response.data.companies[selectedCompany]?.uiSettings;

          if (uiSettings) {
            updateLocalUISettings(uiSettings);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        await sleep(5000);
        return Promise.reject({
          logoutOnFailure: false,
          message: "Couldn't get /user/my/details!",
        });
      }

      await updateDictionaryContext();

      return Promise.resolve();
    },
  };
};
